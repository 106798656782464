import { Title } from '@solidjs/meta';
import { useAction, useNavigate, useSubmission } from '@solidjs/router';
import { clientAction } from '@troon/api-client';
import { ActivityIndicator } from '@troon/ui';
import { createEffect, onMount } from 'solid-js';

export default function LogOut() {
	const logout = useAction(logoutAction);
	const submission = useSubmission(logoutAction);
	const navigate = useNavigate();

	onMount(() => {
		logout(new FormData());
	});

	createEffect(() => {
		if (submission.result || submission.error) {
			navigate('/auth/login', { replace: true });
		}
	});

	return (
		<>
			<Title>Log out | Troon</Title>
			<ActivityIndicator>Logging out…</ActivityIndicator>
		</>
	);
}

const logoutAction = clientAction(
	'POST',
	'/v0/operator/auth/logout',
	{},
	{ revalidate: ['/v0/operator/auth/me', '/v0/operator/facilities'] },
);
